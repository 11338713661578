html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    font-family: 'Dosis', sans-serif;
    font-weight: 300;
    color: white;
    background-color: #212121;
}
.blur {
    filter: blur(5px);
}
header {
    height: 800px;
    background-image: url("../imgs/header2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.nav-content {
    background-color: rgba(110, 0, 0, 0.6);
    font-size: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-content a {
    color: white;
}
.contact {
    cursor: pointer;
}
.navbar-toggler {
    border: none;
}
.fa-times {
    font-size: 1.9rem;
    cursor: pointer;
}
.scrolled {
    background-color: rgba(255, 255, 255, 0.15);
}
button:focus {
    outline: 0;
}
#navbarNavAltMarkup {
    transition: all 0.05s;
}
#header-gradient {
    height: 100%;
    background: linear-gradient(to bottom, rgb(20,18,18) 3%, rgba(20,18,18,0.1) 65%);
}
#welcome-msg {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#header-gradient h1 {
    font-style: bold;
    font-size: 4rem;
}
#header-gradient p {
    font-size: 1.5rem;
}
.container-fluid {
    color: white;
    text-align: center;
}
.hr-style {
    width: 30%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
}
#form {
    height: 470px;
    width: 60%;
    background-color: #212121;
    position: fixed;
    top: 20%; left: 20%;
    border-radius: 2px;
    z-index: 2;
    justify-content: space-around;
}
textarea {
    resize: none;
}